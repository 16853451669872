<template>
  <!-- 容器 -->
  <div class="invoice-container">
    <!-- 标题容器 -->
    <span class="fl left">
      <span  class="f30">泰易达</span>
      <div style="display:inline-block;width:10px">物流</div>
    </span>
    <div class="invoice-title-container">
      <!-- 标题 -->
      <div class="invoice-title">浙江泰易达物流科技有限公司</div>
      <div class="invoice-title">运输托运单</div>
      <!-- 标题底部 -->
      <div class="invoice-title-bottom">
        <div>
          <span>接单时间</span><span class="ml10">{{ info.createTime }}</span>
        </div>
        <!-- 运单号 -->
        <div>
          <span>{{ info.orderNo }}</span>
        </div>
      </div>
    </div>
    <!-- 内容区域 -->
    <div class="invoice-content-container">
      <!-- 头部区域 -->
      <div class="invoice-content-top">
        <!-- 发货方 -->
        <div class="invoice-content-top-title">
          <span>发货方</span>
        </div>
        <!-- 发货方内容区域 -->
        <div class="invoice-content-top-content">
          <div class="invoice-content-top-content-item address pl10">
            <div class="invoice-content-top-content-item-title">
              <span>全称:</span>
            </div>
            <span class="invoice-content-top-content-item-content">{{
                info.consignName
              }}</span>
          </div>
          <div class="invoice-content-top-content-item address pl10">
            <div class="invoice-content-top-content-item-title">地址:</div>
            <span class="invoice-content-top-content-item-content">{{
                info.consignAddress
              }}</span>
          </div>
          <div class="invoice-content-top-content-item item">
            <div
                class="invoice-content-top-content-item-title"
                style="width: 110px"
            >
              <span>&nbsp;联系人:</span>
            </div>
            <span class="invoice-content-top-content-item-content">{{
                info.consignLinkman
              }}</span>
          </div>
          <div class="invoice-content-top-content-item item pl10">
            <div class="invoice-content-top-content-item-title">电话:</div>
            <span class="invoice-content-top-content-item-content">{{
                info.consignMobile
              }}</span>
          </div>
        </div>
        <!--  收货方 -->
        <div class="invoice-content-top-title">
          <span>收货方</span>
        </div>
        <!-- 收货方内容区域 -->
        <div class="invoice-content-top-content">
          <div class="invoice-content-top-content-item address pl10">
            <div class="invoice-content-top-content-item-title">
              <span>全称:</span>
            </div>
            <span class="invoice-content-top-content-item-content">{{
                info.vendeeName
              }}</span>
          </div>
          <div class="invoice-content-top-content-item address pl10">
            <div class="invoice-content-top-content-item-title">地址:</div>
            <span class="invoice-content-top-content-item-content">{{
                info.vendeeAddress
              }}</span>
          </div>
          <div class="invoice-content-top-content-item item">
            <div
                class="invoice-content-top-content-item-title"
                style="width: 110px"
            >
              <span>&nbsp;联系人:</span>
            </div>
            <span class="invoice-content-top-content-item-content">{{
                info.vendeeLinkman
              }}</span>
          </div>
          <div class="invoice-content-top-content-item item pl10">
            <div class="invoice-content-top-content-item-title">电话:</div>
            <span class="invoice-content-top-content-item-content">{{
                info.vendeeMobile
              }}</span>
          </div>
        </div>
      </div>
      <div class="invoice-content-bottom">
        <!-- 左侧信息 -->
        <div class="invoice-content-bottom-content">
          <div class="invoice-content-bottom-content-item">
            <div class="invoice-content-bottom-content-item-title">
              <span>总件数:</span>
            </div>
            <span class="invoice-content-bottom-content-item-content">{{
                info.pkgQty
              }}</span>
          </div>
          <div class="invoice-content-bottom-content-item">
            <div class="invoice-content-bottom-content-item-title">
              总重量(KG)
            </div>
            <span class="invoice-content-bottom-content-item-content">{{
                info.weight
              }}</span>
          </div>
          <div class="invoice-content-bottom-content-item">
            <div class="invoice-content-bottom-content-item-title">
              <span>总体积(m3)</span>
            </div>
            <span class="invoice-content-bottom-content-item-content">{{
                info.volume
              }}</span>
          </div>
          <div class="invoice-content-bottom-content-item">
            <div class="invoice-content-bottom-content-item-title">
              运输时效
            </div>
            <span class="invoice-content-bottom-content-item-content"></span>
          </div>
          <div class="invoice-content-bottom-content-item">
            <div class="invoice-content-bottom-content-item-title">
              货品名称
            </div>
            <span class="invoice-content-bottom-content-item-content"></span>
          </div>
          <div class="invoice-content-bottom-content-item">
            <div class="invoice-content-bottom-content-item-title">包装</div>
            <span class="invoice-content-bottom-content-item-content"></span>
          </div>
        </div>
        <!-- 右侧信息 -->
        <div class="invoice-content-bottom-content">
          <div class="invoice-content-bottom-content-item">
            <div class="invoice-content-bottom-content-item-title center">
              <span>承运商名称</span>
            </div>
            <span class="invoice-content-bottom-content-item-content">{{
                info.carriersName
              }}</span>
          </div>
          <div class="invoice-content-bottom-content-item">
            <div class="invoice-content-bottom-content-item-title center">
              承运商电话
            </div>
            <span class="invoice-content-bottom-content-item-content">{{
                info.driverMobile
              }}</span>
          </div>
          <div class="invoice-content-bottom-content-item">
            <div class="invoice-content-bottom-content-item-title center">
              <span>提货车号</span>
            </div>
            <span class="invoice-content-bottom-content-item-content">{{
                info.truckNo
              }}</span>
          </div>
          <div class="invoice-content-bottom-content-item">
            <div
                class="
                invoice-content-bottom-content-item-title
                center
                item-title
              "
            >
              备注:
            </div>
            <span class="invoice-content-bottom-content-item-content">{{
                info.vendeeLine
              }}</span>
          </div>
        </div>
      </div>
      <!-- 底部区域 -->
      <div class="invoice-content">
        <div class="invoice-content-name">
          <div class="invoice-content-name-top">发货方签名(盖章)</div>
        </div>
        <div class="invoice-content-name">
          <div class="invoice-content-name-top">承运商签名(盖章)</div>
        </div>
        <div class="invoice-content-name" style="width: 400px">
          <div class="invoice-content-name-top">收货方签名(盖章)</div>
        </div>
      </div>
    </div>
    <!--    plain v-if="['10'].includes(info.transStatus)"-->
    <el-button class="button" size="small" type="primary" plain v-if="[8].includes(info.transStatus)"  @click="handleReceive"
    >签收按钮</el-button
    >
  </div>
</template>
<script>
import axios from "axios"
export default {
  props:{
    orderNo:{
      type:String,
    },
    closeTransPort:{
      type:Function
    }
  },
  data () {
    return {
      info: {
        orderNo: "",
        createTime: "",
        consignName: "",
        consignAddress: "",
        consignLinkman: "",
        consignMobile: "",
        vendeeName: "",
        vendeeAddress: "",
        vendeeLinkman: "",
        vendeeMobile: null,
        pkgQty: "",
        weight: "",
        volume: "",
        carriersName: "",
        driverMobile: "",
        truckNo: "",
        vendeeLine: "",
        transStatus: "",
      }, // 运输托运单信息
    }
  },
  methods: {
    /**
     * 查询运输托运单信息
     */

    getInfo () {
      this.$set(this.$data, "info", {})
      axios
          .get(`${this.$apiPath}/api/urban/permit/transport/info`, {
            params: {
              orderNo: this.$route.query.orderNo || this.orderNo,
            },
          })
          .then(res=> {
            console.log(res.data)
            this.$set(this.$data, "info", res.data.data || {})

          })
          .catch((err) => {
            console.error(err)
          })
      // this.info.orderNo = '123456'
    },
    // 点击确认签收
    handleReceive () {
      this.$confirm("是否确认签收", '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
      }).then(() => {
        // 确认
        axios
            .get(`${this.$apiPath}/api/urban/permit/transport/signBill`, {
              params: {
                orderNo: this.$route.query.orderNo || this.orderNo,
              },
            })
            .then( res => {
              console.log(res.data)
              this.$message({
                type: res.data.success === true ? "success" : "error",
                message: res.data.success === true ? '确认收货成功' : res.data.msg ,
                // type: 'success',
                // message: '签收成功',
              })
              if(res.data.success !== true)return

              this.closeTransPort()
            })
            .catch((err) => {
              console.error(err)
            })
      }).catch(() => {})
    }
  },
  mounted () {
    this.getInfo()
  },
}
</script>
<style lang="scss" scoped>
.invoice-container {
  * {
    box-sizing: border-box;
  }
  .button {
    float: right;
    margin-top: 10px;
    margin-right: 45px;
  }
  background: #ffffff;
  min-height: 630px;
  padding: 20px 100px 25px 70px;
  position: relative;
  border-radius: 4px;
  box-shadow: 0 2px 12px 0 rgba(0, 0, 0, 0.1);
  font-size: 16px;
  &:hover {
    .invoice-remove {
      visibility: visible;
    }
  }
  .invoice-remove {
    width: 25px;
    position: absolute;
    top: -12px;
    right: -12px;
    cursor: pointer;
    visibility: hidden;
  }
  .invoice-check-status {
    position: absolute;
    top: -20px;
    left: -60px;
  }
  .f30{
    font-size: 30px;
    font-weight: 400;
  }
  .left{
    position: absolute;
    top: 30px;
  }
  .invoice-title-container {
    width: 1000px;
    height: 95px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
    .invoice-title {
      height: 40px;
      position: relative;
      font-size: 24px;
      font-weight: 500;
      color: #000000;
    }

    .invoice-title-bottom {
      width: 100%;
      height: 30px;
      line-height: 15px;
      display: flex;
      justify-content: space-between;
      align-items: center;
      .ml10 {
        margin-left: 10px;
      }
    }
  }
  .invoice-content-container {
    height: 460px;
    width: 1000px;
    border: 2px solid #000000;
    border-right: none;
    .invoice-content-top,
    .invoice-content-bottom {
      height: 120px;
      display: flex;
      .invoice-content-top-title,
      .invoice-content-bottom-title,
      .invoice-content-top-label,
      .invoice-content-bottom-label {
        height: 100%;
        display: flex;
        flex-direction: column;
        justify-content: space-around;
        align-items: center;
        color: #000000;
        border-right: 2px solid #000000;
      }
      .invoice-content-top-title,
      .invoice-content-bottom-title {
        width: 100px;
      }
      .invoice-content-top-label,
      .invoice-content-bottom-label {
        width: 35px;
      }
      .invoice-content-top-content,
      .invoice-content-bottom-content {
        width: 400px;
        border-right: 2px solid #000000;
        .address {
          height: 40px;
          line-height: 40px;
        }
        .item {
          height: 26px;
          line-height: 27px;
        }
        .pl10 {
          padding-left: 10px;
        }
        .invoice-content-top-content-item,
        .invoice-content-bottom-content-item {
          display: flex;
          align-items: center;
          border-bottom: 2px solid #000000;
          &:last-child {
            border-bottom: none;
          }
          .invoice-content-top-content-item-title,
          .invoice-content-bottom-content-item-title {
            width: 100px;
            border-right: 2px solid #000000;
            color: #000000;
          }
          .invoice-content-top-content-item-content,
          .invoice-content-bottom-content-item-content {
            flex: 1;
            font-size: 15px;
            text-align: center;
            color: #000000;
            line-height: 17px;
          }
        }
      }
      .invoice-content-bottom-content {
        width: 500px;
        .center {
          text-align: center;
        }
        .item-title {
          height: 79px;
          line-height: 79px;
        }
      }
      .invoice-content-bottom-remark {
        flex: 1;
        padding: 5px;
        word-break: break-all;
        font-size: 15px;
        text-align: left;
        color: #000000;
      }
    }
    .invoice-content-top {
      border-bottom: 2px solid #000000;
      height: 133px;
    }
    .invoice-content-bottom {
      height: 134px;
    }
    .invoice-content {
      min-height: 240px;
      display: flex;
      border-top: 2px solid #000000;
      .invoice-content-name {
        width: 300px;
        height: 187px;
        border-right: 2px solid #000000;
        .invoice-content-name-top,
        .invoice-content-name-bottom {
          height: 30px;
          line-height: 30px;
          text-align: left;
          font-size: 18px;
          color: #000000;
        }
        .invoice-content-name-content {
          min-height: 180px;
          padding: 0 5px;
        }
      }
      .invoice-content-specs {
        width: 145px;
        min-height: 240px;
        border-right: 2px solid #000000;
        .invoice-content-specs-top {
          height: 30px;
          line-height: 30px;
          text-align: center;
          color: #000000;
        }
        .invoice-content-specs-content {
          min-height: 210px;
          padding: 0 5px;
        }
      }
      .invoice-content-unit {
        width: 75px;
        min-height: 240px;
        border-right: 2px solid #000000;
        .invoice-content-unit-top {
          height: 30px;
          line-height: 30px;
          text-align: center;
          color: #000000;
        }
        .invoice-content-unit-content {
          min-height: 210px;
          padding: 0 5px;
        }
      }
      .invoice-content-number {
        width: 130px;
        min-height: 240px;
        border-right: 2px solid #000000;
        .invoice-content-number-top {
          height: 30px;
          line-height: 30px;
          text-align: center;
          color: #000000;
        }
        .invoice-content-number-content {
          min-height: 210px;
          padding: 0 5px;
        }
      }
      .invoice-content-price {
        width: 140px;
        min-height: 240px;
        border-right: 2px solid #000000;
        .invoice-content-price-top {
          height: 30px;
          line-height: 30px;
          text-align: center;
          color: #000000;
        }
        .invoice-content-price-content {
          min-height: 210px;
          padding: 0 5px;
        }
      }
      .invoice-content-preTaxAmt {
        width: 160px;
        min-height: 240px;
        border-right: 2px solid #000000;
        .invoice-content-preTaxAmt-top,
        .invoice-content-preTaxAmt-bottom {
          height: 30px;
          line-height: 30px;
          text-align: center;
        }
        .invoice-content-preTaxAmt-top {
          color: #000000;
        }
        .invoice-content-preTaxAmt-bottom {
          color: #000000;
        }
        .invoice-content-preTaxAmt-content {
          min-height: 180px;
          padding: 0 5px;
        }
      }
      .invoice-content-taxRate {
        width: 60px;
        min-height: 240px;
        border-right: 2px solid #000000;
        .invoice-content-taxRate-top {
          height: 30px;
          line-height: 30px;
          text-align: center;
          color: #000000;
        }
        .invoice-content-taxRate-content {
          min-height: 210px;
          padding: 0 5px;
        }
      }
      .invoice-content-taxAmt {
        width: 178px;
        min-height: 240px;
        .invoice-content-taxAmt-top,
        .invoice-content-taxAmt-bottom {
          height: 30px;
          line-height: 30px;
          text-align: center;
        }
        .invoice-content-taxAmt-top {
          color: #000000;
        }
        .invoice-content-taxAmt-bottom {
          color: #000000;
        }
        .invoice-content-taxAmt-content {
          min-height: 180px;
          padding: 0 5px;
        }
      }
    }
    .invoice-statistics {
      height: 40px;
      display: flex;
      align-items: center;
      border-top: 2px solid #000000;
      .invoice-statistics-title {
        width: 310px;
        height: 100%;
        display: flex;
        justify-content: center;
        align-items: center;
        color: #000000;
        border-right: 2px solid #000000;
      }
      .invoice-statistics-content {
        flex: 1;
        height: 100%;
        padding: 0 10px;
        display: flex;
        justify-content: space-between;
        align-items: center;
        font-size: 20px;
        color: #000000;
        .invoice-statistics-content-right {
          display: flex;
          justify-content: space-between;
          align-items: center;
        }
      }
    }
  }
}

</style>
